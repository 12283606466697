@font-face {
    font-family: "StolzlRegular";
    src: url("StolzlRegular/StolzlRegular.eot");
    src: url("StolzlRegular/StolzlRegular.eot?#iefix")format("embedded-opentype"),
    url("StolzlRegular/StolzlRegular.woff") format("woff"),
    url("StolzlRegular/StolzlRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

.font_stolzl_regular
{
    font-family:StolzlRegular,sans-serif !important;
}